import Vue from 'vue';

// const setting = {
    // colorNabvar: '#3691E3',
    // logoLogin: require('@/assets/icons/appdeltendero-04.png'),
    // macbook: require('@/assets/pages/img/login/macbook.png'),
    // welcome: 'Bienvenido a tu App del tendero',
    // logoPerfil: require('@/assets/icons/appdeltendero-02.png'),
    // logoNavbar: require('@/assets/icons/appdeltendero-03.png'),
// }

Vue.mixin({
  // data(){
  //     return setting;
  // },
  methods: {
    /**
     * [myNotify Despliega notificaciones] ***---https://github.com/euvl/vue-notification/---***
     * @param  {[string]} title       [Título de la notificación]
     * @param  {[string]} message     [Mensaje de la notificación]
     * @param  {[string]} type        [Color de la notificación (warn, error, success, active, etc) ]
     * @return {[notificación]}       [Devuelve la notificación]
     */

    myNotify(type, title, message) {
      return this.$notify({
        type: type,
        title: '<div style="font-size:1.25rem;font-weight:500;">'+title+'</div>',
        text: '<div style="font-size:1rem;font-weight:400;">'+message+'</div>',
        duration: 3000,
        ignoreDuplicates: true,
      });
    }
  }
});

//DATOS DE LAS IMAGENES DE CONFIGURACIÓN
/*
logoLogin: {
    DIMENSION = 1266 X 387
    ANCHO = 1266 PIXELES
    ALTO = 387 PIXELES
    COLOR FONDO = TRANSPARENTE
}

macbook: {
    DIMENSION = 1405 X 812
    ANCHO = 1405 PIXELES
    ALTO = 812 PIXELES
    COLOR FONDO = TRANSPARENTE
}

logoPerfil: {
    DIMENSION = 512 X 512
    ANCHO = 512 PIXELES
    ALTO = 512 PIXELES
    COLOR FONDO = TRANSPARENTE
}

logoNavbar: {
    DIMENSION = 2639 X 808
    ANCHO = 2639 PIXELES
    ALTO = 808 PIXELES
    COLOR FONDO = TRANSPARENTE
}

 */


