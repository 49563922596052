export default {
  appLoader: false,
  userLogged: false,
  user: {
    token: "",
      name: "",
      username: "",
      email: "",
      user_id: 0,
      exp: 0,
      status: "",
      picture: "",
      identification_number: 0,
      birthdate: "",
      phone: "",
      firebase_uid: "",
      type_user: "",
      address_id: 0,
      address_alias: "",
      address_detail: "",
      lat: "",
      lon: "",
      city: "",
      address: "",
  },
  cart: {
    usuario:{
      id: null,
      address_id: null,
      total: 0,
      quanty: 0,
    },
    orden:[]
  },
  products:[],
}