import * as constants from '@/constants';

export default {
  capitalize(_string) {
    return _string.charAt(0).toUpperCase() + _string.slice(1);
  },
  cutString(longStr, max = 20) {
      const maxChar = max;
      if (longStr.length >= maxChar) {
        return `${longStr.slice(0, maxChar)}...`;
      }
      return longStr;
  },
  isMail(string) {
    var expresion = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(expresion.test(string)) {
      return true;
    }else{
      return false;
      }
  },
  isUrl(string) {
    var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
    var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
    var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
    if (typeof string !== 'string') {
      return false;
    }
    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }
    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }
    if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }
    return false;
  },
  validatePass(pass) {
    var expresionRegular = /^(?=.*\d+)(?=.*[a-zA-Z])[\x20-\x7E]{6,}$/;
    if(pass.match(expresionRegular)) {
      return true;
    }else {
      return false;
    }
  },
  allowed_extension() {
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'txt', 'csv', 'xls', 'xlsx'];
  },
  checkExt(filename) {
    var extensions = this.allowed_extension();
    var nameFile = filename.name;
    nameFile = nameFile.toLowerCase();
    var ext = nameFile.substr(nameFile.lastIndexOf('.') + 1);
    var isValid = extensions.indexOf(ext) > -1;
    return { 'valid': isValid, 'ext': ext };
  },
  formatCurrency(data, Type, Style, Typecurrency) {
    const type = Type || constants.LOCALE;
    const style = Style || 'currency';
    const currency = Typecurrency || constants.CURRENCY;
    const objData = {style:style, currency:currency, maximumFractionDigits:2}
    const formatterPeso = new Intl.NumberFormat(type, objData);
    return formatterPeso.format(data);
  },
  statusOrders(status) {
    switch(status) {
      case 1: return 'order.status_received';
      case 2: return 'order.status_confirmed';
      case 3: return 'order.status_rejected';
      case 4: return 'order.status_delivered';
      case 5: return 'order.status_to_cancel';
      case 6: return 'order.status_canceled';
      default: return 'order.status_to_resolve';
    }
  },
  colorcircleStatus(status) {
    switch(status) {
      case 1: return 'blue';
      case 2: return 'green';
      case 3: return 'red';
      case 4: return 'green';
      case 5: return 'amber';
      case 6: return 'red';
      default: return 'grey-darken-3';
    }
  },
  decodeBase64(str){
    return Buffer.from(str, 'base64').toString('ascii');
  },
};