import axios from 'axios';
import * as constants from '@/constants';
import Floppy from '@/helpers/IndexedDB.js';

//Add a request interceptor
axios.interceptors.request.use(async (config) => {
  const getToken = await Floppy.getAll('users');
  if (getToken.length > 0) {
      const myToken = `JWT ${getToken[0].token}`;
      config.headers = {
          ...config.headers,
          'Authorization': myToken,
      };
      return config;
  }
  return config;
}, (error) => {
  console.warn('Error: interceptors request use:', error);
  return Promise.reject(error);
});

export default {
  get(url) {
    return axios.get(constants.API_BASE_URL + url)
      .then((response) => new Promise((resolve) => {
        resolve(response);
      }))
      .catch((error) => new Promise((resolve)=> {
        resolve(error.response);
      }));
  },

  post(url, data = {}) {
  return axios.post(constants.API_BASE_URL + url, data)
    .then((response) => new Promise((resolve) => {
      resolve(response);
    }))
    .catch((error) => new Promise((resolve)=> {
      resolve(error.response);
    }));
  },

  put(url, data = {}) {
    return axios.put(constants.API_BASE_URL + url, data)
      .then((response) => new Promise((resolve) => {
          resolve(response);
      }))
      .catch((error) => new Promise((resolve)=> {
        resolve(error.response);
      }));
  },

  delete(url, data) {
    let Delete;
    if(data){
      Delete = axios.delete(constants.API_BASE_URL + url,{data:data})
    }else{
      Delete = axios.delete(constants.API_BASE_URL + url);
    }
    return Delete
      .then((response) => new Promise((resolve) => {
        resolve(response);
      }))
      .catch((error) => new Promise((resolve)=> {
        resolve(error.response);
      }));
  },

  upload(url, formData) {
    return axios.post(constants.API_BASE_URL + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => new Promise((resolve) => {
      resolve(response);
    }))
    .catch((error) => new Promise((resolve)=> {
      resolve(error.response);
    }));
  },
};
