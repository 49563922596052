import { auth } from './InitFirebase.js';
export default {
    login(credenciales) {
        return auth.signInWithEmailAndPassword(credenciales.email, credenciales.password);
    },
    isLogin() {
        return new Promise((resolve, reject) => {
            auth.onAuthStateChanged((user) => resolve(user));
        })
    },
    logout() {
        return new Promise((resolve, reject)=> {
            resolve(auth.signOut());
        })
    },
    register(user) {
        return auth.createUserWithEmailAndPassword(user.email, user.password);
    },
    currentUser() {
        return auth.currentUser;
    }
}