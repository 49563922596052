export default {
  APP_LOADER(state, payload) {
    state.appLoader = payload;
  },
  USER_LOGGED(state, payload) {
    state.userLogged = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_CART(state, payload) {
    //ACTUALIZAR LOS TOTALES.
    let total_u = 0;
    let quanty_u = 0;
    let shiping_u = 0;
    for(const o of payload.orden){
      let total_o = 0;
      let quanty_o = 0;
      for(const p of o.products){
        let total_p = (p.cant*p.price);
        total_o = (total_o+total_p);
        quanty_o = (quanty_o+p.cant);
        o.subtotal = total_o;
        o.cant_total_products = quanty_o
      }
      o.total = (o.subtotal+o.delivery_cost);
      total_u = (total_u+total_o);
      quanty_u = (quanty_u+quanty_o);
      shiping_u = (shiping_u+o.delivery_cost);
    }
    payload.usuario.subtotal = total_u;
    payload.usuario.total = (total_u+shiping_u);
    payload.usuario.quanty = quanty_u;
    //ACTUALIZAR CARRITO.
    state.cart = payload;
    localStorage.setItem('shoppingCart', JSON.stringify(payload));
  },
  ROVOVE_CART_ZEROS(state,payload) {
    for(const [oi, ov] of state.cart.orden.entries()){
      if(ov.shop === payload.shop){
        for(const [pi, pv] of ov.products.entries()){
          if(pv.product_id === payload.product){
              ov.products.splice(pi,1);
              if(ov.products.length < 1){
                state.cart.orden.splice(oi,1);
              }
            break;
          }
        }
      break;
      }
    }
  },
  CLEAR_CART(state,payload) {
    const newCart = {
      "usuario":{
        "id":payload.user_id,
        "address_id":payload.address_id,
        "subtotal":0,
        "total":0,
        "quanty":0,
      },
      "orden":[],
    };
    state.cart = newCart;
    localStorage.setItem('shoppingCart', JSON.stringify(newCart));
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_QUANTY_PRODUCTS(state, payload){
    for(const or of state.cart.orden){
      if(or.shop === payload.shop){
        for(const op of or.products){
          for(const pp of state.products){
            if(op.product_id === pp.product.id){
              pp.quanty = op.cant;
              break;
            }
          }
        }
        break;
      }
    }
  },
}