<template>
  <v-dialog v-model="dialog" persistent fullscreen transition="dialog-top-transition" >
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined rounded color="pediidos" v-bind="attrs" v-on="on" >{{$t('edit')}} </v-btn>
    </template>
    <v-card class="mx-auto pa-5 mt-5" max-width="1264" elevation="4">
      <v-toolbar dark color="pediidos">
        <v-btn icon dark @click="clearForm()" ><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>{{$t('location')}} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :disabled="val_save" @click="updateAddress()" >{{$t('confirm')}}</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title style="word-break: normal;" class="headline text-center align-center justify-center  py-5">{{$t('onboarding.qn_shop_location')}}</v-card-title>
      <v-card-text class="caption text-right pediidos--text "> {{$t('onboarding.msg_shop_address')}} </v-card-text>
        <v-form class="px-5 pb-5 text-center" lazy-validation>
          <v-row>
            <v-col class="py-0 " cols="12" md="6" >
            <v-autocomplete
              @change="onChangeCity()"
              v-model="address.city"
              :items="cities"
              :rules="rules.city"
              :label="this.$t('city')"
              :placeholder="this.$t('city')"
              color="pediidos"
              item-color="pediidos"
              prepend-icon="mdi-city-variant-outline"
              auto-select-first
              chips
              small-chips
              outlined
            ></v-autocomplete>
            </v-col>
            <v-col class="py-0 " cols="12" md="6">
            <v-text-field
              color="pediidos"
              :label="this.$t('address')"
              :placeholder="this.$t('onboarding.address_placeholder')"
              v-model.trim="address.address"
              :rules="rules.address"
              prepend-icon="mdi-map-marker"
              append-icon="mdi-magnify"
              autocomplete="off"
              outlined
              @click:append="validedAddress()"
              @keyup.enter="validedAddress()"
              :disabled="( address.city == '' )"
            ></v-text-field>
            </v-col>
            <v-col class="py-0 " cols="12" md="6" >
            <v-text-field
              v-model="address.detail"
              :rules="rules.detail"
              color="pediidos"
              :label="this.$t('detail')"
              :placeholder="this.$t('onboarding.detail_placeholder')"
              prepend-icon="mdi-comment-alert-outline"
              autocomplete="off"
              outlined
              :disabled="( address.city == '' )"
            ></v-text-field>
            </v-col>
            <v-col class="py-0 " cols="12" md="6" >
            <v-text-field
            v-model="address.alias"
            :rules="rules.alias"
            color="pediidos"
            :label="this.$t('alias')"
            :placeholder="this.$t('onboarding.alias_placeholder')"
            prepend-icon="mdi-tag"
            autocomplete="off"
            outlined
            :disabled="( address.city == '' )"
            ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-text class=" py-0 caption pediidos--text text-right ">{{$t('onboarding.msg_shop_location')}}</v-card-text>
        <v-sheet class="mx-1  pb-16">
          <div :id="id_map" style="width: 100%; height: 45vh"></div>
        </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import Api from '@/helpers/api.js';
  import Floppy from '@/helpers/IndexedDB.js';
  import * as constants from '@/constants';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'edit_address',
    props: ['id_map'],
    data() {
      return {
        cities: [],
        dialog: false,
        address: {
          address: '',
          city: '',
          detail: '',
          alias: '',
          lat: undefined,
          lng: undefined,
        },
        rules: {
          city: [v => !!v || this.$t('error.required_city'), v => (v && v != '' ) || this.$t('error.empty_city'),],
          address: [v => !!v || this.$t('error.required_address'), v => (v && v.length <= 100 && v.length >= 4 ) || this.$t('error.length_address'),],
          detail: [v => !!v || this.$t('error.required_detail'), v => (v && v.length <= 50 && v.length >= 4 ) || this.$t('error.length_detail'),],
          alias: [v => !!v || this.$t('error.required_alias'), v => (v && v.length <= 50 && v.length >= 4 ) || this.$t('error.length_alias'),],
        },
      }
    },
    watch: {
      dialog(value) {
        if(value){
          setTimeout(()=>{this.getCurrentPosition();}, 200);
        }
      },
    },
    async mounted(){
      this.getCyties();
    },
    methods: {
      ...mapMutations(['SET_USER',]),
      async getCurrentPosition() {
        const options = {enableHighAccuracy:true,maximumAge:0,timeout:5000};
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.getBrowserLocation, this.errorBrowserLocation, options);
        } else {
            this.errorBrowserLocation()
        }
      },
      async errorBrowserLocation() {
        this.getBrowserLocation(null);
        this.myNotify('warn',this.$t('_warning'),this.$t('error.current_position'));
      },
      async getBrowserLocation(position) {
        if(position !== null){
          Anuket.run('#'+this.id_map,{'zoom': 12,'latlng': {'lat': position.coords.latitude,'lng': position.coords.longitude} });
          this.addMarker(position.coords.latitude,position.coords.longitude);
          this.inversegeocoder(position.coords.latitude,position.coords.longitude);
        }else{
          Anuket.run('#'+this.id_map,{'zoom': 10,'latlng': constants.COORDINATES_DEFAULT });
        }
      },
      async getCyties() {
        Api.post('shopkeepers/cities/').then((result)=> {
          let myCities = [];
          result.data.forEach(function(e) {
            myCities.push(e.name);
          });
          this.cities = myCities;
        }).catch((error)=> {
          this.myNotify('error',this.$t('_error'),this.$t('error.load_cities'));
          console.log('This is error ',error);
        })
      },
      updateAddress() {
        let data = {
            user_id: this.user.user_id,
            alias: this.address.alias,
            address: this.address.address,
            detail: this.address.detail,
            lat: this.address.lat.toString(),
            lon: this.address.lng.toString(),
            city: this.address.city
          }
        Api.put('users/add/address/', data).then((result)=> {
          if(result.status < 300) {
            this.user.address_alias = this.address.alias;
            this.user.address_detail = this.address.detail;
            this.user.address = this.address.address;
            this.user.lat = this.address.lat.toString();
            this.user.lon = this.address.lng.toString();
            this.user.city = this.address.city;
            Floppy.update('users', this.user, this.user.firebase_uid);
            this.myNotify('success',this.$t('_success'),this.$t('onboarding.msg_update_address'));
            this.clearForm();
            if(this.$route.path.includes('home/list')){
              setTimeout(()=>{ this.$router.go(); }, 250);
            }
          }else{
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
            console.log('This is error ',result.detail);
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      addMarker(lat,lng){
        Anuket.clearMap();
        var options = {
          draggable: true,
          onDragend: (e, coords) =>{
            this.address.lat = coords.lat;
            this.address.lng = coords.lng;
            this.inversegeocoder(coords.lat,coords.lng);
          },
        };
        Anuket.addMarker({lat:lat, lng: lng}, options);
        Anuket.centerMap(lat, lng);
        Anuket.setZoom(18);
      },
      validedAddress(){
        if(this.address.address.length > 3 ) {
          this.geocoder(this.address.address);
        }
      },
      geocoder(address) {
        this.address.lat = constants.COORDINATES_DEFAULT.lat;
        this.address.lng = constants.COORDINATES_DEFAULT.lng;
        var options = {
          country: constants.COUNTRY,
        }
        var full_address = address+','+this.address.city+','+constants.COUNTRY;
        Anuket.geocoder(full_address, (coords, results, status) => {
          if (status === 'OK'){
            this.addMarker(coords.lat,coords.lng);
            this.address.lat = coords.lat;
            this.address.lng = coords.lng;
            this.address.address = results[0].formatted_address;
          }
        }, options);
      },
      inversegeocoder(lat,lng){
        Anuket.inverseGeocoder({lat: lat, lng: lng}, (address, results, status) => {
          this.address.address = address
        });
      },
      clearForm(){
        this.address.address = '';
        this.address.city = '';
        this.address.detail = '';
        this.address.alias = '';
        this.address.lat = undefined;
        this.address.lng = undefined;
        this.dialog = false;
      },
      onChangeCity(){
        this.address.address = '';
        this.address.lat = undefined;
        this.address.lng = undefined;
      },
    },
    computed: {
      ...mapState(['appLoader','user','somestate']),
      val_save(){
        if(this.address.city.length < 1 || this.address.address.length < 1 || this.address.detail.length < 1 ||
          this.address.alias.length < 1 || this.address.lat == undefined || this.address.lng == undefined){
          return true
        }
        return false
      },
    },
  }
</script>

<style type="text/css" scoped>
</style>