// export const API_BASE_URL = 'http://localhost:8888/' //LOCALHOST
export const API_BASE_URL = 'https://api-pe-dot-pediidos-f9d72.appspot.com/'
export const REALTIME_DATABASE = "https://pediidos-peru.firebaseio.com"
export const COORDINATES_DEFAULT = {'lat': -12.046358,'lng': -77.042714}

export const COUNTRY = 'Peru'
export const LOCALE = 'es-CO'
export const CURRENCY = 'PEN'
export const TIMEZONE = "-05:00"

export const COLOR = '#25D366'
export const COMPANY_NAME = 'SuPedido'
export const FAVICON = 'https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Flogos-pediidos%2Fsupedidos-color.png?alt=media'
export const LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Flogos-pediidos%2Fsupedidos-color.png?alt=media'
export const LOGO_URL_W = 'https://firebasestorage.googleapis.com/v0/b/pediidos-f9d72.appspot.com/o/misitio%2Flogos-pediidos%2Fsupedidos-color.png?alt=media'
export const ALLOW_SIGNUP = true
export const ALLOW_SOUND = true
export const ALLOW_CHECKOUT_COMMENT = true

export const MAP_LIB = ['places','drawing']
export const APIKEY = process.env.VUE_APP_APIKEY // ...8JxNE

export const SUPPORTED_LOCALE = [
    {locale:'es-CO',ico2:'/icons/locales/ES.svg',ico3:'/icons/locales/ESP.svg'},
    {locale:'pt-BR',ico2:'/icons/locales/PT.svg',ico3:'/icons/locales/POR.svg'},
    {locale:'en-US',ico2:'/icons/locales/EN.svg',ico3:'/icons/locales/ENG.svg'},
]