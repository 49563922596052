import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import * as constants from '@/constants';
import Utils from "@/helpers/Utils.js";

const firebaseConfig = {
  apiKey: Utils.decodeBase64(constants.APIKEY),
  authDomain: "pediidos-f9d72.firebaseapp.com",
  databaseURL: constants.REALTIME_DATABASE,
  projectId: "pediidos-f9d72",
  storageBucket: "pediidos-f9d72.appspot.com",
  messagingSenderId: "353212274543",
  appId: "1:353212274543:web:827813fc7dca820b"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();

export { db, auth, storage, database }
